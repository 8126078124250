import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { sHtml } from '../clever-ui-kit/style'

// CONTEXT
import { StateContext, DispatchContext, initialState } from '../context'

// COMPONENTS CONST
import SEO from '../components/seo'
import { sTheme, sBlog, sTitle } from './blog'
import loadable from '@loadable/component'
import { IntroBlog } from '../clever-ui-kit/intros'
import { T } from '../clever-ui-kit/typo'
// const AsyncComponent = loadable(props => import(`../clever-ui-kit/components`))

const Intro = loadable(() => import('../clever-ui-kit/intros/Intro/Intro'))
const Workflow = loadable(() =>
  import('../clever-ui-kit/workflows/Workflow/Workflow')
)
const NewsLetter = loadable(() =>
  import('../clever-ui-kit/newsletter/newsletterComponent/newsletterComponent')
)
const TabServices = loadable(() =>
  import('../clever-ui-kit/tabs/TabServices/TabServices')
)
const Features = loadable(() =>
  import('../clever-ui-kit/features/Features/Features')
)
const BlogMinis = loadable(() =>
  import('../clever-ui-kit/minis/BlogMinis/BlogMinis')
)
const Partners = loadable(() =>
  import('../clever-ui-kit/partners/Partners/Partners')
)
const FormItems = loadable(() =>
  import('../clever-ui-kit/forms/FormItems/FormItems')
)
const PortfolioMinis = loadable(() =>
  import('../clever-ui-kit/portfolio/PortfolioMinis/PortfolioMinis')
)
const Projects = loadable(() =>
  import('../clever-ui-kit/portfolio/Projects/Projects')
)
const Team = loadable(() =>
  import('../clever-ui-kit/team/Team/Team')
)
const BlogsSearch = loadable(() =>
  import('../clever-ui-kit/pages/BlogsSearch/BlogsSearch')
)
const CareerSearch = loadable(() =>
  import('../clever-ui-kit/pages/CareerSearch/CareerSearch')
)
const BriefMinis = loadable(() =>
  import('../clever-ui-kit/minis/BriefMinis/BriefMinis')
)
const FilesMinis = loadable(() =>
  import('../clever-ui-kit/minis/FilesMinis/FilesMinis')
)
const Services = loadable(() =>
  import('../clever-ui-kit/services/Services/Services')
)
const Map = loadable(() => import('../clever-ui-kit/map/Map'))
const Subpages = loadable(() => import('../clever-ui-kit/subpages/Subpages'))

const Components = {
  Intro,
  Workflow,
  TabServices,
  Features,
  BlogMinis,
  Partners,
  FormItems,
  PortfolioMinis,
  Projects,
  Team,
  BlogsSearch,
  CareerSearch,
  BriefMinis,
  FilesMinis,
  NewsLetter,
  Services,
  Map,
  Subpages,
}

// const Components = {
//   // Intro: '../clever-ui-kit/intros/Intro/Intro',
//   // Workflow: '../clever-ui-kit/workflows/Workflow',
//   // TabServices: '../clever-ui-kit/tabs/TabServices',
//   // Features: '../clever-ui-kit/features/Features',
//   // BlogMinis: '../clever-ui-kit/minis/BlogMinis',
//   // Partners: '../clever-ui-kit/partners/Partners',
//   // FormItems: '../clever-ui-kit/forms/FormItems',
//   // PortfolioMinis: '../clever-ui-kit/portfolio/PortfolioMinis',
//   // Projects: '../clever-ui-kit/portfolio/Projects',
//   // BlogsSearch: '../clever-ui-kit/pages/BlogSearch',
//   // CareerSearch: '../clever-ui-kit/pages/CareerSearch',
//   // BriefMinis: '../clever-ui-kit/minis/BriefMinis',
//   // FilesMinis: '../clever-ui-kit/minis/FilesMinis',
// }

export default function PageTemplate({ data, ...props }) {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  // const language = state?.language ? state.language : initialState.language
  const theme = state?.theme ? state.theme : initialState.theme
  const pageData = data.thisPage
  const page = data.thisPage?.frontmatter
  const sections = data.sections.nodes.map((node) => ({ ...node.frontmatter }))
  const introsmall = data.thisPage?.frontmatter?.introsmall
  // A SCRIPT FOR DELETING FALSY

  const reducedSections = sections.map((section) =>
    Object.keys(section)
      .filter((e) => section[e] !== null)
      .reduce((o, e) => {
        o[e] = section[e]
        return o
      }, {})
  )

  /*eslint-disable */
  useEffect(() => {
    dispatch({ type: 'SET_MENU', payload: false })
  }, [])

  useEffect(() => {
    dispatch({ type: 'SET_LANGUAGE', payload: page.language })
  }, [])
  /*eslint-enable */

  const emptyTitle = 'Buduj Świadomy Marketing!'
  const emptyDesc =
    'Stworzymy dla Ciebie dział marketingu, dzięki któremu wzrośnie efektywność sprzedaży w Twojej firmie'

  return (
    <>
      <SEO
        title={page.seo_title === null ? emptyTitle : page.seo_title}
        description={page.seo_desc === null ? emptyDesc : page.seo_desc}
        image={page.image_socials?.publicURL}
        noIndex={page?.noIndex ? true : false}
        slug={page.isIndex ? '/' : `/${page.language}/${page.url}`}
      />

      {introsmall && (
        <>
          {(page.image_background || page.image_socials) && (
            <IntroBlog
              title={page.title}
              subtitle={page.description}
              image={page.image_background || page.image_socials}
              theme={theme}
              language={'pl'}
            />
          )}
          <div css={sTitle}>
            <T
              d={64}
              t={48}
              m={32}
              mb={0.5}
              bold
              variant="h1"
              extraCss={sTitle.headline}>
              {page.title}
            </T>
            {page.description && (
              <T d={20} t={18} m={14} o={1} normal>
                {page.description}
              </T>
            )}
          </div>
          {pageData.html && (
            <article
              css={[sHtml, sBlog, sTheme(theme), serviceWidth]}
              dangerouslySetInnerHTML={{ __html: pageData.html }}
            />
          )}
        </>
      )}
      {pageData.html && pageData.frontmatter.url && !introsmall && page.maxWidth1000 && (
        <div
          dangerouslySetInnerHTML={{ __html: pageData.html }}
          css={[sHtml, sBlog, sTheme(theme), serviceWidth]}
        />
      )}
      {pageData.html && pageData.frontmatter.url && !introsmall && !page.maxWidth1000 && (
        <div
          dangerouslySetInnerHTML={{ __html: pageData.html }}
          css={[sHtml, sBlog, sTheme(theme)]}
        />
      )}
      {reducedSections.map(
        (section, reactKey) =>
          Components[section.component] &&
          section.language === page.language &&
          React.createElement(Components[section.component], {
            key: reactKey,
            theme,
            data: { ...section },
            pageData: { ...page },
          })
      )}
      {page.components?.map(
        ({ component }, reactKey) =>
          Components[component] &&
          React.createElement(Components[component], {
            key: reactKey,
            theme,
            pageData: { ...page },
          })
      )}
      {/* {reducedSections.map((section, reactKey) => {
        // if (section.component === 'Intro') {
        //   return (
        //     <OtherComponent
        //       path={`../clever-ui-kit/intros/Intro/Intro`}
        //       key={reactKey}
        //       theme={theme}
        //       data={section}
        //       pageData={page}
        //     />
        //   )
        // }
        // if (
        //   Components[section.component] &&
        //   section.language === page.language
        // ) {
        //   const OtherComponent = loadable(() =>
        //     import(`${Components[section.component]}`)
        //   )
        //   return (
        //     <OtherComponent
        //       key={reactKey}
        //       theme={theme}
        //       data={section}
        //       pageData={page}
        //     />
        //   )
        // }
        // return null
        // React.createElement(Components[section.component], {
        //   key: reactKey,
        //   theme,
        //   data: { ...section },
        //   pageData: { ...page },
        // })
      //  })}
      /* {page.components?.map(
        ({ component }, reactKey) =>
          Components[component] &&
          React.createElement(Components[component], {
            key: reactKey,
            theme,
            pageData: { ...page },
          })
      )} */}
    </>
  )
}

const serviceWidth = {
  maxWidth: '1000px',
}

export const query = graphql`
  query($slug: String!, $sections: [String], $language: String!) {
    thisPage: markdownRemark(fileAbsolutePath: { regex: $slug }) {
      html
      frontmatter {
        title
        name
        url
        introsmall
        components {
          component
        }
        language
        description
        seo_title
        seo_desc
        noIndex
        maxWidth1000
        isIndex
        image_background {
          childImageSharp {
            fluid(
              maxWidth: 640
              quality: 100
              toFormat: JPG
              jpegProgressive: true
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        # image_socials {
        #   publicURL
        # }
      }
    }
    sections: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/sections/" }
        frontmatter: { name: { in: $sections }, language: { eq: $language } }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        html
        frontmatter {
          name
          language
          component
          subpages {
            title
            link
            image {
              childImageSharp {
                fluid(
                  maxWidth: 640
                  quality: 100
                  toFormat: JPG
                  jpegProgressive: true
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          # image_socials {
          #   publicURL
          # }
          slug_section
          align
          title
          titleObject {
            text
            variant
          }
          titleSimple {
            fontWeight
            variant
            text
            uppercase
            color
          }
          titleComplex {
            variant
            texts {
              fontWeight
              text
              uppercase
              gradient {
                angle
                colors {
                  color
                }
              }
            }
          }
          subtitleObject {
            fontWeight
            variant
            text
            uppercase
            color
          }
          descriptionObject {
            fontWeight
            variant
            text
            color
          }
          buttonGradient {
            text
            uppercase
            textColor
            gradient {
              angle
              colors {
                color
              }
            }
            link_page
            link_section
          }
          subtitle
          description
          button {
            text
            uppercase
            link_page
            link_section
          }
          buttonSmall {
            link_page
            link_section
            text
            text_before
          }
          imageQuality {
            name
            childImageSharp {
              fluid(
                maxWidth: 1920
                quality: 100
                toFormat: JPG
                jpegProgressive: true
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image {
            name
            childImageSharp {
              fluid(
                maxWidth: 400
                quality: 100
                toFormat: JPG
                jpegProgressive: true
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tabs {
            color
            titles {
              smaller
              text
            }
            descs {
              text
            }
            tools {
              image {
                name
                publicURL
              }
            }
            images {
              image {
                childImageSharp {
                  fluid(
                    maxWidth: 400
                    quality: 100
                    toFormat: JPG
                    jpegProgressive: true
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          workflows {
            title
            description
            image {
              publicURL
            }
          }
          features {
            buttonSmall {
              link_page
              link_section
              text
              text_before
              uppercase
            }
            slug_section
            description
            props {
              title
            }
            image {
              name
              childImageSharp {
                fluid(
                  maxWidth: 200
                  quality: 100
                  toFormat: JPG
                  jpegProgressive: true
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            titleObject {
              text
              variant
            }
          }
          projects {
            title
            description
            link
            image {
              name
              childImageSharp {
                fluid(
                  maxWidth: 400
                  quality: 100
                  toFormat: JPG
                  jpegProgressive: true
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          partners {
            image {
              name
              publicURL
            }
          }
          form_options {
            title
            name
            description
          }
          form_input {
            name
            email
            phone
            message
          }
          form_agreements {
            text
          }
          form_button {
            send
            fill
          }
          form_success {
            title
            text
          }
          form_failure {
            title
            text
          }
          form_config {
            fromname
            # fromemail
            toemail
            # replyto
            # cc
            # bcc
            messagetype
          }

          briefs {
            title
            image {
              name
              childImageSharp {
                fluid(
                  maxWidth: 400
                  quality: 100
                  toFormat: JPG
                  jpegProgressive: true
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            file {
              publicURL
            }
          }
          files {
            title
            image {
              extension
              publicURL
              name
              childImageSharp {
                fluid(
                  maxWidth: 400
                  quality: 100
                  toFormat: JPG
                  jpegProgressive: true
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            file {
              publicURL
            }
          }
        }
      }
    }
  }
`
